import React, {useEffect, useState, createContext} from 'react';
import firebaseConfig from "./FirebaseConfig"

export let AuthContext = createContext({});

export const AuthProvider = ({children})=>{
    const [currentUser, setCurrentUser] = useState(null);

    useEffect(()=>{
        firebaseConfig.auth().onAuthStateChanged(user=>{
        if(user){
          user.getIdTokenResult().then(idTokenResult =>{
            if(idTokenResult.claims.developer){
              console.log(firebaseConfig.auth().currentUser)
              setCurrentUser(firebaseConfig.auth().currentUser);
              console.log(currentUser)
            }
            else
            {
              firebaseConfig
                .auth()
                .signOut()
                .then(() => {
                  console.log.log('out')
                });
            }
          })
        }
        else
        {
            setCurrentUser(null)
        }
      })
    },[]);

    return (
      <AuthContext.Provider value={{currentUser}}>
        {children}
      </AuthContext.Provider>
    );
};