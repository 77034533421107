import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Typography,Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  name: {
    marginTop: theme.spacing(1)
  }
}));

const Profile = props => {
  const { className, data, ...rest } = props;
  const classes = useStyles();

  return (
    <div >
      {data?<div>
  
        <Grid container spacing={3} direction="column"
            justify="center"
            alignItems="center">
          <Grid item xs={12}>
            <Avatar
              alt="Person"
              src={data.photoURL}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">{data.displayName}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">{data.email}</Typography>
          </Grid>

        </Grid>
        
      </div>
      :<></>}
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
