import React, { useEffect, useState } from 'react';
import ButtonBase from "@material-ui/core/ButtonBase"
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles, makeStyles,ThemeProvider, createMuiTheme  } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField'
import StarIcon from '@material-ui/icons/Star';

const theme = createMuiTheme({
  overrides: {
    MuiTypography: {
      caption: {
        fontSize: [8, "!important"]
      }
    }
  }
});

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    padding:'10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText('#252525'),
    backgroundColor: '#252525',
    '&:hover': {
      backgroundColor: '#252525',
    },
  },
}))(Button);

export default function AddReviewForm(props) {
  const classes = useStyles();
  const [score, setScore] = useState(1);
  const [message, setMessage] = useState("");
  const [showForm, setShowForm] = useState(true)
  const [loading,setLoading] = useState(true)
  const [rateController, setRateController] = useState([true,false,false,false,false]);

  const {
    idBrand,
    idProduct,
    idUser,
    userName
  } = props;


  const handleSubmit = event => {
    event.preventDefault();
    /*let addReview = firebaseConfig.functions().httpsCallable('addReview');
      addReview({
        idBrand:    idBrand,
        userName:   'testusername',
        idProduct:  idProduct,
        idUser:     idUser,
        score:      score,
        message:    message,
        deviceInfo: {
            coords: {
                latitude:   17.108601,
                longitude:  -95.3018286
            },
            osVersion:      "Android",
            buildVersion:   "6.0",
            modelName:      "Samsung Note 4",
            deviceID:       "qa-23440"
        }
      }).then((result)=> {console.log(result)}).catch((error)=> {
          console.log(error);   
      })*/

    fetch("https://reviews-axo-dev.wallia.io/addReview",{ 
      mode: 'cors', // no-cors, *cors, same-origin
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjhiMjFkNWE1Y2U2OGM1MjNlZTc0MzI5YjQ3ZDg0NGE3YmZjODRjZmYiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiQ3Jpc3RpYW4gU2VycmFubyIsImRldmVsb3BlciI6dHJ1ZSwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL2dydXBvLWF4by1kZXYiLCJhdWQiOiJncnVwby1heG8tZGV2IiwiYXV0aF90aW1lIjoxNjI0OTMxMDk4LCJ1c2VyX2lkIjoiSEdFZEVnZk9vN1Y1SDRjOXF5cjVDMmI3QkJ1MSIsInN1YiI6IkhHRWRFZ2ZPbzdWNUg0YzlxeXI1QzJiN0JCdTEiLCJpYXQiOjE2MjQ5MzEwOTgsImV4cCI6MTYyNDkzNDY5OCwiZW1haWwiOiJjcmlzdGlhbi5zZXJyYW5vQHdhbGxpYS5pbyIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJlbWFpbCI6WyJjcmlzdGlhbi5zZXJyYW5vQHdhbGxpYS5pbyJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.VlDqZGYY9h7ZVvhfFohe_xPDYp01YfKtOPd_6EMoRXzSmjVhMDxjrE1aPr0Ho4j3DMLg538yH6PO-Iy0RO1qrXR0zsBJZ5zflSHWCZPpw6kZr1RnoGMzLvE4q5z6cWG02fm-QHcG9f2zXrSCbSIyHJ_pf6roGZnx68v97sbrDpoQcZQ_C6I_LuiEjcg7XH36my6DUEzfb_AVVcZhcmJeWEsGHZBAzMWKQig9BT6Bhi6zmwnJlGzymPSAbrcq7X6l6K6ULwzV8pbZrU94BVThTiiSADkP9wFkO83MCysGqBkuRlIwys03rnVCS6rWsQgSKQilvpnct8KsK2ZKp8f_dw',
      },
      body: JSON.stringify({
        idBrand: idBrand,
        userName:userName,
        idProduct: idProduct,
        idUser:idUser,
        score:      score,
        message:    message,
        deviceInfo: {
            coords: {
                latitude:   17.108601,
                longitude:  -95.3018286
            },
            osVersion:      "Android",
            buildVersion:   "6.0",
            modelName:      "Samsung Note 4",
            deviceID:       "qa-23440"
        }
    })
    })
    .then(res => res.json())
    .then(
      (result) => {
        console.log(result)
      },(error) => {
        console.log(error)
      }
    )
  }

  const handleTextFieldChange=event => {
    console.log(event.target.value)
    setMessage(event.target.value);
  }

  useEffect(()=>{
    /*const getReviewsByUser = firebaseConfig.functions().httpsCallable('getReviewsByUser')
    getReviewsByUser({
      idBrand:idBrand,
      idProduct:idProduct,
      idUser:idUser
    }).then((result) => {
      console.log(result.data)
      setLoading(false)
      if(result.data)
        setShowForm(false)
    }).catch(error => {
      console.log(error)
    })*/

    fetch("https://reviews-axo-dev.wallia.io/getReviewsByUser",{
      mode: 'cors', // no-cors, *cors, same-origin
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjhiMjFkNWE1Y2U2OGM1MjNlZTc0MzI5YjQ3ZDg0NGE3YmZjODRjZmYiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiQ3Jpc3RpYW4gU2VycmFubyIsImRldmVsb3BlciI6dHJ1ZSwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL2dydXBvLWF4by1kZXYiLCJhdWQiOiJncnVwby1heG8tZGV2IiwiYXV0aF90aW1lIjoxNjI0OTMxMDk4LCJ1c2VyX2lkIjoiSEdFZEVnZk9vN1Y1SDRjOXF5cjVDMmI3QkJ1MSIsInN1YiI6IkhHRWRFZ2ZPbzdWNUg0YzlxeXI1QzJiN0JCdTEiLCJpYXQiOjE2MjQ5MzEwOTgsImV4cCI6MTYyNDkzNDY5OCwiZW1haWwiOiJjcmlzdGlhbi5zZXJyYW5vQHdhbGxpYS5pbyIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJlbWFpbCI6WyJjcmlzdGlhbi5zZXJyYW5vQHdhbGxpYS5pbyJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.VlDqZGYY9h7ZVvhfFohe_xPDYp01YfKtOPd_6EMoRXzSmjVhMDxjrE1aPr0Ho4j3DMLg538yH6PO-Iy0RO1qrXR0zsBJZ5zflSHWCZPpw6kZr1RnoGMzLvE4q5z6cWG02fm-QHcG9f2zXrSCbSIyHJ_pf6roGZnx68v97sbrDpoQcZQ_C6I_LuiEjcg7XH36my6DUEzfb_AVVcZhcmJeWEsGHZBAzMWKQig9BT6Bhi6zmwnJlGzymPSAbrcq7X6l6K6ULwzV8pbZrU94BVThTiiSADkP9wFkO83MCysGqBkuRlIwys03rnVCS6rWsQgSKQilvpnct8KsK2ZKp8f_dw',
        },
      body: JSON.stringify({
        idBrand:idBrand,
        idProduct:idProduct,
        idUser:idUser
      })
    })
    .then(res => res.json())
    .then(
      (result) => {
        console.log(result)
        setLoading(false)
        if(result.length>0)
          setShowForm(false)
      },(error) => {
        console.log(error)
    })
  },[])

  const HandleButtonRating=value=>{
    console.log(value)
    setScore(value)
    if(value===1)
      setRateController([true,false,false,false,false])
    if(value===2)
      setRateController([true,true,false,false,false])
    if(value===3)
      setRateController([true,true,true,false,false])
    if(value===4)
      setRateController([true,true,true,true,false])
    if(value===5)
      setRateController([true,true,true,true,true])
  }

  return (  
    <>
    {loading?
      <></>:
      <>
        {showForm?
          <form className={classes.form} onSubmit={handleSubmit} noValidate autoComplete="off">
            <Grid container spacing={1}
                  direction="column"
                  justify="center"
                  alignItems="center">
              <Grid item lg={12} sm={12} xl={12} xs={12}>
                <Grid container spacing={3} direction="row" justify="center" alignItems="center">
                  <Grid item xs={2}>
                    <ButtonBase onClick={() => HandleButtonRating(1)}>
                      <Paper style={{ 
                        backgroundColor: rateController[0] ?'#39be7e':'white',
                        color: rateController[0]?'#fff':'black',
                        paddingLeft:5,
                        paddingRight:5,
                      }}>
                      <Grid container spacing={0} direction="column" justify="center" alignItems="center">
                        <Grid item xs={12}>
                          <StarIcon style={{ fontSize: 40 }} />
                        </Grid>
                        <Grid item xs={12}>
                          <ThemeProvider theme={theme}>
                            <Typography component={'span'} variant="caption">
                              Very bad
                            </Typography>
                          </ThemeProvider>
                        </Grid>
                      </Grid>
                      </Paper>
                    </ButtonBase>
                  </Grid>
                  <Grid item xs={2}>
                    <ButtonBase onClick={() => HandleButtonRating(2)}>
                      <Paper style={{ 
                        backgroundColor: rateController[1] ?'#39be7e':'white',
                        color: rateController[1]?'#fff':'black',
                        paddingLeft:5,
                        paddingRight:5,
                      }}>
                      <Grid container spacing={0} direction="column" justify="center" alignItems="center">
                        <Grid item xs={12}>
                          <StarIcon style={{ fontSize: 40 }} />
                        </Grid>
                        <Grid item xs={12}>
                          <ThemeProvider theme={theme}>
                            <Typography  component={'span'}  variant="caption">
                              Bad
                            </Typography>
                          </ThemeProvider>
                        </Grid>
                      </Grid>
                      </Paper>
                    </ButtonBase>
                  </Grid>
                  <Grid item xs={2}>
                    <ButtonBase onClick={() => HandleButtonRating(3)}>
                      <Paper style={{ 
                        backgroundColor: rateController[2] ?'#39be7e':'white',
                        color: rateController[2]?'#fff':'black',
                        paddingLeft:5,
                        paddingRight:5,
                      }}>
                      <Grid container spacing={0} direction="column" justify="center" alignItems="center">
                        <Grid item xs={12}>
                          <StarIcon style={{ fontSize: 40 }} />
                        </Grid>
                        <Grid item xs={12}>
                          <ThemeProvider theme={theme}>
                            <Typography  component={'span'} variant="caption">
                              Acceptable
                            </Typography>
                          </ThemeProvider>
                        </Grid>
                      </Grid>
                      </Paper>
                    </ButtonBase>
                  </Grid>
                  <Grid item xs={2}>
                    <ButtonBase onClick={() => HandleButtonRating(4)}>
                      <Paper style={{ 
                        backgroundColor: rateController[3] ?'#39be7e':'white',
                        color: rateController[3]?'#fff':'black',
                        paddingLeft:5,
                        paddingRight:5,
                      }}>
                      <Grid container spacing={0} direction="column" justify="center" alignItems="center">
                        <Grid item xs={12}>
                          <StarIcon style={{ fontSize: 40 }} />
                        </Grid>
                        <Grid item xs={12}>
                          <ThemeProvider theme={theme}>
                            <Typography  component={'span'} variant="caption">
                              Good
                            </Typography>
                          </ThemeProvider>
                        </Grid>
                      </Grid>
                      </Paper>
                    </ButtonBase>
                  </Grid>
                  <Grid item xs={2}>
                    <ButtonBase onClick={() => HandleButtonRating(5)}>
                      <Paper style={{ 
                        backgroundColor: rateController[4] ?'#39be7e':'white',
                        color: rateController[4]?'#fff':'black',
                        paddingLeft:5,
                        paddingRight:5,
                      }}>
                      <Grid container spacing={0} direction="column" justify="center" alignItems="center">
                        <Grid item xs={12}>
                          <StarIcon style={{ fontSize: 40 }} />
                        </Grid>
                        <Grid item xs={12}>
                          <ThemeProvider theme={theme}>
                            <Typography  component={'span'}  variant="caption">
                              Very good
                            </Typography>
                          </ThemeProvider>
                        </Grid>
                      </Grid>
                      </Paper>
                    </ButtonBase>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={12} sm={12} xl={12} xs={12}>
                <Grid container spacing={3} direction="row" justify="center" alignItems="center">
                  <Grid item lg={9} sm={9} xl={9} xs={9}>
                    <TextField
                        variant="outlined"
                        required
                        onChange={handleTextFieldChange}
                        size="small"
                        id="comment"
                        label="Comment"
                        name="comment"
                      />
                  </Grid>
                  <Grid item lg={3} sm={3} xl={3} xs={3}>   
                    <ColorButton    
                      type="submit"
                      size="large"
                    >
                      Send
                    </ColorButton>
                  </Grid> 
                </Grid>
              </Grid>
            </Grid>
          </form>:
          <div style={{margin:'10px'}}>
            <Typography
              component={'span'}
              variant="body2"
              style = {{fontWeight:'bolder',fontSize:'24px',color:'#28B463'}}
              >
              {'Thanks for comment!'}
            </Typography>
          </div>
        }
      </> 
    }
    </>
  );
}