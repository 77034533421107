import React from 'react';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Button } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: 'none'
  },
  title: {
    fontSize: 24,
    textTransform: 'none',
    fontFamily: "'Roboto Condensed', sans-serif",
    color: '#fff',
    fontWeight: 700,
    fontFamilySecondary: "'Roboto Condensed', sans-serif"
  },
  flexGrow: {
    flexGrow: 1
  },
  home: {
    fontSize: 16,
    textTransform: 'none',
    fontFamily: "'Roboto Condensed', sans-serif",
    color: '#fff',
    fontWeight: 700,
    fontFamilySecondary: "'Roboto Condensed', sans-serif"
  }
}));

const Topbar = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  //const history = useHistory();

  const handleOnClick = () => {
    //history.push('/');
  };

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color="primary"
      position="fixed">
      <Toolbar>
        <Typography variant="h6" className={classes.title}>
          AXO GROUP PORTAL DEVELOPER
        </Typography>
        <div className={classes.flexGrow} />
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string
};

export default Topbar;
