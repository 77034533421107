import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import clsx from 'clsx';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import {makeStyles, withStyles } from '@material-ui/core/styles';
import DiscreteSliderComponent from './components/DiscreteSliderComponent';
import ReactJson from 'react-json-view'
const useStyles = makeStyles((theme) => ({
    paper: {
        margin:theme.spacing(2),
        padding: theme.spacing(10),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        },
    typography:{
        fontSize:'bolder',
        marginTop:'10px',
        marginBottom:'10px',
        color:'gray',
        fontWeight:'bolder'
    },
    root: {
        flexGrow: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        backgroundColor:'#252525',
        maxHeight:'200px',
        overflow: 'auto',
        color:'#00FF6B',
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
}));

const SuccessfulTypography = withStyles({
    root: {
      color: "#28B463"
    }
})(Typography);

const ErrorTypography = withStyles({
    root: {
        color: "#CB4335"
    }
})(Typography);

export default function AverageViewComponent(props) {
    const classes = useStyles();

    const {brands, ...rest}=props
    const [goodResponses, setGoodReponses] = useState([])
    const [badResponses, setBadResponses] = useState([])
    const [total, setTotal] = useState([])
    const [numberRequest,setNumberRequest]=useState(0)
    const [executionTime, setExecutionTime] = useState(0)

    const [goodResponses1, setGoodReponses1] = useState([])
    const [badResponses1, setBadResponses1] = useState([])
    const [total1, setTotal1] = useState([])
    const [numberRequest1,setNumberRequest1]=useState(0)
    const [executionTime1, setExecutionTime1] = useState(0)

    const [goodResponses2, setGoodReponses2] = useState([])
    const [badResponses2, setBadResponses2] = useState([])
    const [total2, setTotal2] = useState([])
    const [numberRequest2,setNumberRequest2]=useState(0)
    const [executionTime2, setExecutionTime2] = useState(0)

    const [goodResponses3, setGoodReponses3] = useState([])
    const [badResponses3, setBadResponses3] = useState([])
    const [total3, setTotal3] = useState([])
    const [numberRequest3,setNumberRequest3]=useState(0)
    const [executionTime3, setExecutionTime3] = useState(0)

    const [goodResponses4, setGoodReponses4] = useState([])
    const [badResponses4, setBadResponses4] = useState([])
    const [total4, setTotal4] = useState([])
    const [numberRequest4,setNumberRequest4]=useState(0)
    const [executionTime4, setExecutionTime4] = useState(0)


    const [product, setProduct] = useState('');
    const [product1, setProduct1] = useState('');
    const [product2, setProduct2] = useState('');
    const [product3, setProduct3] = useState('');
    const [product4, setProduct4] = useState('');

    const [brand, setBrand] = useState('');
    const [brand1, setBrand1] = useState('');
    const [brand2, setBrand2] = useState('');
    const [brand3, setBrand3] = useState('');
    const [brand4, setBrand4] = useState('');

    const [products,setProducts] = useState(['4064037288769','FORUM MID','FY4976-077','SUPERSTAR','ED6093-369'])

    const [localBrands,setLocalBrands] = useState(brands)


    const [lastKey1,setLastKey1] = useState(null);
    const [lastKey2,setLastKey2] = useState(null);
    const [lastKey3,setLastKey3] = useState(null);
    const [lastKey4,setLastKey4] = useState(null);

    const [limit1,setLimit1] = useState(2);
    const [limit2,setLimit2] = useState(2);
    const [limit3,setLimit3] = useState(2);
    const [limit4,setLimit4] = useState(2);

    const handleChangeSelect = (event) => {
        setBrand(event.target.value);
    };

    const handleChangeSelect1 = (event) => {
        setProduct(event.target.value);
    };

    const handleChangeSelect2 = (event) => {
        setLastKey1(null)
        setBrand1(event.target.value);
    };

    const handleChangeSelect3 = (event) => {
        setLastKey1(null)
        setProduct1(event.target.value);
    };

    const handleChangeSelect4 = (event) => {
        setLastKey2(null)
        setBrand2(event.target.value);
    };

    const handleChangeSelect5 = (event) => {
        setLastKey2(null)
        setProduct2(event.target.value);
    };

    const handleChangeSelect6 = (event) => {
        setLastKey3(null)
        setBrand3(event.target.value);
    };

    const handleChangeSelect7 = (event) => {
        setLastKey3(null)
        setProduct3(event.target.value);
    };

    const handleChangeSelect8 = (event) => {
        setLastKey4(null)
        setBrand4(event.target.value);
    };

    const handleChangeSelect9 = (event) => {
        setLastKey4(null)
        setProduct4(event.target.value);
    };

    async function AddReviewsMakeLoadTest()
    {
        //let idProduct = new Date().getTime()
        let idUser = new Date().getTime()
        let userName= 'user-test'
        
        let message =   'message-test'
        const good = []
        const bad = []
        const total =[]
        var a = performance.now();

        for(let index=0;index<numberRequest;index++){
            const score = Math.floor(Math.random() * 5)
            try{
                const response = await fetch("https://reviews-axo-dev.wallia.io/addReview",{
                    mode: 'cors',
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjhiMjFkNWE1Y2U2OGM1MjNlZTc0MzI5YjQ3ZDg0NGE3YmZjODRjZmYiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiQ3Jpc3RpYW4gU2VycmFubyIsImRldmVsb3BlciI6dHJ1ZSwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL2dydXBvLWF4by1kZXYiLCJhdWQiOiJncnVwby1heG8tZGV2IiwiYXV0aF90aW1lIjoxNjI0OTMxMDk4LCJ1c2VyX2lkIjoiSEdFZEVnZk9vN1Y1SDRjOXF5cjVDMmI3QkJ1MSIsInN1YiI6IkhHRWRFZ2ZPbzdWNUg0YzlxeXI1QzJiN0JCdTEiLCJpYXQiOjE2MjQ5MzEwOTgsImV4cCI6MTYyNDkzNDY5OCwiZW1haWwiOiJjcmlzdGlhbi5zZXJyYW5vQHdhbGxpYS5pbyIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJlbWFpbCI6WyJjcmlzdGlhbi5zZXJyYW5vQHdhbGxpYS5pbyJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.VlDqZGYY9h7ZVvhfFohe_xPDYp01YfKtOPd_6EMoRXzSmjVhMDxjrE1aPr0Ho4j3DMLg538yH6PO-Iy0RO1qrXR0zsBJZ5zflSHWCZPpw6kZr1RnoGMzLvE4q5z6cWG02fm-QHcG9f2zXrSCbSIyHJ_pf6roGZnx68v97sbrDpoQcZQ_C6I_LuiEjcg7XH36my6DUEzfb_AVVcZhcmJeWEsGHZBAzMWKQig9BT6Bhi6zmwnJlGzymPSAbrcq7X6l6K6ULwzV8pbZrU94BVThTiiSADkP9wFkO83MCysGqBkuRlIwys03rnVCS6rWsQgSKQilvpnct8KsK2ZKp8f_dw',
                    },
                    body: JSON.stringify({
                        idBrand: brand,
                        idProduct: product,
                        idUser: idUser,
                        userName: userName,
                        score: score+1,
                        message: message,
                        deviceInfo: {
                            coords: {
                                latitude:   17.108601,
                                longitude:  -95.3018286
                            },
                            osVersion:      "Android",
                            buildVersion:   "6.0",
                            modelName:      "Samsung Note 4",
                            deviceID:       "qa-23440"
                        }
                    })
                })

                console.log(response)
                good.push(response)
                total.push(response)

            }catch(err){
                bad.push(err)
                total.push(err)
            }
        }

        var b = performance.now();
        console.log('It took ' + (b - a) + ' ms.');

        setExecutionTime((b-a))

        setBadResponses(bad)

        setGoodReponses(good);

        setTotal(total)

    }

    async function GetReviewsMakeLoadTest()
    {
        const good = []
        const bad = []
        const total = []
        var a = performance.now();
            try{
                const response = await fetch("https://reviews-axo-dev.wallia.io/getReviewsByProduct",{
                    mode: 'cors',
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjhiMjFkNWE1Y2U2OGM1MjNlZTc0MzI5YjQ3ZDg0NGE3YmZjODRjZmYiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiQ3Jpc3RpYW4gU2VycmFubyIsImRldmVsb3BlciI6dHJ1ZSwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL2dydXBvLWF4by1kZXYiLCJhdWQiOiJncnVwby1heG8tZGV2IiwiYXV0aF90aW1lIjoxNjI0OTMxMDk4LCJ1c2VyX2lkIjoiSEdFZEVnZk9vN1Y1SDRjOXF5cjVDMmI3QkJ1MSIsInN1YiI6IkhHRWRFZ2ZPbzdWNUg0YzlxeXI1QzJiN0JCdTEiLCJpYXQiOjE2MjQ5MzEwOTgsImV4cCI6MTYyNDkzNDY5OCwiZW1haWwiOiJjcmlzdGlhbi5zZXJyYW5vQHdhbGxpYS5pbyIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJlbWFpbCI6WyJjcmlzdGlhbi5zZXJyYW5vQHdhbGxpYS5pbyJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.VlDqZGYY9h7ZVvhfFohe_xPDYp01YfKtOPd_6EMoRXzSmjVhMDxjrE1aPr0Ho4j3DMLg538yH6PO-Iy0RO1qrXR0zsBJZ5zflSHWCZPpw6kZr1RnoGMzLvE4q5z6cWG02fm-QHcG9f2zXrSCbSIyHJ_pf6roGZnx68v97sbrDpoQcZQ_C6I_LuiEjcg7XH36my6DUEzfb_AVVcZhcmJeWEsGHZBAzMWKQig9BT6Bhi6zmwnJlGzymPSAbrcq7X6l6K6ULwzV8pbZrU94BVThTiiSADkP9wFkO83MCysGqBkuRlIwys03rnVCS6rWsQgSKQilvpnct8KsK2ZKp8f_dw',
                    },
                    body: JSON.stringify({
                        idBrand:brand1,
                        idProduct: product1==='none'?null:product1,
                        limit:limit1,
                        lastKey:lastKey1
                    })
                })

                console.log(response)
                if(response.data!==null){
                    setLastKey1(response.data.nextKey)
                    good.push(response.data.reviews)
                    total.push(response.data.reviews)
                }
            }catch(err){
                bad.push(err)
                total.push(err)
            }

        var b = performance.now();
        
        setExecutionTime1((b-a))

        if(total.length>0){
            setBadResponses1(bad)

            setGoodReponses1(good);

            setTotal1(total)
        }
    }

    async function GetNotApprovedReviewsMakeLoadTest()
    {
        const good = []
        const bad = []
        const total = []
        var a = performance.now();

        
            try{

                const response = await fetch("https://reviews-axo-dev.wallia.io/getNotApprovedReviewsByProduct",{
                    mode: 'cors',
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjhiMjFkNWE1Y2U2OGM1MjNlZTc0MzI5YjQ3ZDg0NGE3YmZjODRjZmYiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiQ3Jpc3RpYW4gU2VycmFubyIsImRldmVsb3BlciI6dHJ1ZSwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL2dydXBvLWF4by1kZXYiLCJhdWQiOiJncnVwby1heG8tZGV2IiwiYXV0aF90aW1lIjoxNjI0OTMxMDk4LCJ1c2VyX2lkIjoiSEdFZEVnZk9vN1Y1SDRjOXF5cjVDMmI3QkJ1MSIsInN1YiI6IkhHRWRFZ2ZPbzdWNUg0YzlxeXI1QzJiN0JCdTEiLCJpYXQiOjE2MjQ5MzEwOTgsImV4cCI6MTYyNDkzNDY5OCwiZW1haWwiOiJjcmlzdGlhbi5zZXJyYW5vQHdhbGxpYS5pbyIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJlbWFpbCI6WyJjcmlzdGlhbi5zZXJyYW5vQHdhbGxpYS5pbyJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.VlDqZGYY9h7ZVvhfFohe_xPDYp01YfKtOPd_6EMoRXzSmjVhMDxjrE1aPr0Ho4j3DMLg538yH6PO-Iy0RO1qrXR0zsBJZ5zflSHWCZPpw6kZr1RnoGMzLvE4q5z6cWG02fm-QHcG9f2zXrSCbSIyHJ_pf6roGZnx68v97sbrDpoQcZQ_C6I_LuiEjcg7XH36my6DUEzfb_AVVcZhcmJeWEsGHZBAzMWKQig9BT6Bhi6zmwnJlGzymPSAbrcq7X6l6K6ULwzV8pbZrU94BVThTiiSADkP9wFkO83MCysGqBkuRlIwys03rnVCS6rWsQgSKQilvpnct8KsK2ZKp8f_dw',
                    },
                    body: JSON.stringify({
                        idBrand:brand2,
                    idProduct: product2==='none'?null:product2,
                    limit:limit2,
                    lastKey:lastKey2
                    })
                })

                console.log(response)
                if(response.data!==null){
                    setLastKey2(response.data.nextKey)
                    good.push(response.data.reviews)
                    total.push(response.data.reviews)
                }
            }catch(err){
                bad.push(err)
                total.push(err)
            }

        var b = performance.now();
        
        setExecutionTime2((b-a))

        if(total.length>0){
            setBadResponses2(bad)

            setGoodReponses2(good);

            setTotal2(total)
        }
    }

    async function GetApprovedReviewsMakeLoadTest()
    {
        const good = []
        const bad = []
        const total = []
        var a = performance.now();
            try{

                const response = await fetch("https://reviews-axo-dev.wallia.io/getApprovedReviewsByProduct",{
                    mode: 'cors',
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjhiMjFkNWE1Y2U2OGM1MjNlZTc0MzI5YjQ3ZDg0NGE3YmZjODRjZmYiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiQ3Jpc3RpYW4gU2VycmFubyIsImRldmVsb3BlciI6dHJ1ZSwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL2dydXBvLWF4by1kZXYiLCJhdWQiOiJncnVwby1heG8tZGV2IiwiYXV0aF90aW1lIjoxNjI0OTMxMDk4LCJ1c2VyX2lkIjoiSEdFZEVnZk9vN1Y1SDRjOXF5cjVDMmI3QkJ1MSIsInN1YiI6IkhHRWRFZ2ZPbzdWNUg0YzlxeXI1QzJiN0JCdTEiLCJpYXQiOjE2MjQ5MzEwOTgsImV4cCI6MTYyNDkzNDY5OCwiZW1haWwiOiJjcmlzdGlhbi5zZXJyYW5vQHdhbGxpYS5pbyIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJlbWFpbCI6WyJjcmlzdGlhbi5zZXJyYW5vQHdhbGxpYS5pbyJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.VlDqZGYY9h7ZVvhfFohe_xPDYp01YfKtOPd_6EMoRXzSmjVhMDxjrE1aPr0Ho4j3DMLg538yH6PO-Iy0RO1qrXR0zsBJZ5zflSHWCZPpw6kZr1RnoGMzLvE4q5z6cWG02fm-QHcG9f2zXrSCbSIyHJ_pf6roGZnx68v97sbrDpoQcZQ_C6I_LuiEjcg7XH36my6DUEzfb_AVVcZhcmJeWEsGHZBAzMWKQig9BT6Bhi6zmwnJlGzymPSAbrcq7X6l6K6ULwzV8pbZrU94BVThTiiSADkP9wFkO83MCysGqBkuRlIwys03rnVCS6rWsQgSKQilvpnct8KsK2ZKp8f_dw',
                    },
                    body: JSON.stringify({
                        idBrand:brand3,
                        idProduct: product3==='none'?null:product3,
                        limit:limit3,
                        lastKey:lastKey3
                    })
                })

                console.log(response)
                if(response.data!==null){
                    setLastKey3(response.data.nextKey)
                    good.push(response.data.reviews)
                    total.push(response.data.reviews)
                }
            }catch(err){
                bad.push(err)
                total.push(err)
            }

        var b = performance.now();
        
        setExecutionTime3((b-a))

        if(total.length>0){
            setBadResponses3(bad)

            setGoodReponses3(good);

            setTotal3(total)
        }
    }

    async function GetPendingReviewsMakeLoadTest()
    {
        const good = []
        const bad = []
        const total = []
        var a = performance.now();
            try
            {
                const response = await fetch("https://reviews-axo-dev.wallia.io/getPendingReviewsByProduct",{
                    mode: 'cors',
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjhiMjFkNWE1Y2U2OGM1MjNlZTc0MzI5YjQ3ZDg0NGE3YmZjODRjZmYiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiQ3Jpc3RpYW4gU2VycmFubyIsImRldmVsb3BlciI6dHJ1ZSwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL2dydXBvLWF4by1kZXYiLCJhdWQiOiJncnVwby1heG8tZGV2IiwiYXV0aF90aW1lIjoxNjI0OTMxMDk4LCJ1c2VyX2lkIjoiSEdFZEVnZk9vN1Y1SDRjOXF5cjVDMmI3QkJ1MSIsInN1YiI6IkhHRWRFZ2ZPbzdWNUg0YzlxeXI1QzJiN0JCdTEiLCJpYXQiOjE2MjQ5MzEwOTgsImV4cCI6MTYyNDkzNDY5OCwiZW1haWwiOiJjcmlzdGlhbi5zZXJyYW5vQHdhbGxpYS5pbyIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJlbWFpbCI6WyJjcmlzdGlhbi5zZXJyYW5vQHdhbGxpYS5pbyJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.VlDqZGYY9h7ZVvhfFohe_xPDYp01YfKtOPd_6EMoRXzSmjVhMDxjrE1aPr0Ho4j3DMLg538yH6PO-Iy0RO1qrXR0zsBJZ5zflSHWCZPpw6kZr1RnoGMzLvE4q5z6cWG02fm-QHcG9f2zXrSCbSIyHJ_pf6roGZnx68v97sbrDpoQcZQ_C6I_LuiEjcg7XH36my6DUEzfb_AVVcZhcmJeWEsGHZBAzMWKQig9BT6Bhi6zmwnJlGzymPSAbrcq7X6l6K6ULwzV8pbZrU94BVThTiiSADkP9wFkO83MCysGqBkuRlIwys03rnVCS6rWsQgSKQilvpnct8KsK2ZKp8f_dw',
                    },
                    body: JSON.stringify({
                        idBrand:brand4,
                        idProduct: product4==='none'?null:product4,
                        limit:limit4,
                        lastKey:lastKey4
                    })
                })

                 console.log(response)
                if(response.data!==null){
                    setLastKey4(response.data.nextKey)
                    good.push(response.data.reviews)
                    total.push(response.data.reviews)
                }
            }catch(err){
                bad.push(err)
                total.push(err)
            }
        var b = performance.now();      
        setExecutionTime4((b-a))
        if(total.length>0){
            setBadResponses4(bad)
            setGoodReponses4(good);
            setTotal4(total)
        }
    }

    const handleChange = (event, newValue) => {
        setNumberRequest(newValue);
    };  
    const handleChange1 = (event, newValue) => {
        setNumberRequest1(newValue);      
    };
    const handleChange2 = (event, newValue) => {
        setNumberRequest2(newValue);    
    }; 
    const handleChange3 = (event, newValue) => {
        setNumberRequest3(newValue);      
    }; 
    const handleChange4 = (event, newValue) => {      
        setNumberRequest4(newValue);     
    }; 

    const handleChangeSelectLimit1=event => {
        console.log(event.target.value)
        setLimit1(parseInt(event.target.value));
    }
    const handleChangeSelectLimit2=event => {
        console.log(event.target.value)
        setLimit2(parseInt(event.target.value));
    }
    const handleChangeSelectLimit3=event => {
        console.log(event.target.value)
        setLimit3(parseInt(event.target.value));
    }
    const handleChangeSelectLimit4=event => {
        console.log(event.target.value)
        setLimit4(parseInt(event.target.value));
    }

    return (
        <div className={classes.root}>    
            <Grid container spacing={3}>
                <Grid item item lg={12} sm={12} xl={12} xs={12} >
                    <Paper className={classes.paper}>
                        <div style={{margin:5}}>
                            <Typography variant='h4'>
                                AddReviews
                            </Typography>
                        </div>
                        <div>
                            <DiscreteSliderComponent onChange={handleChange} max={1024}></DiscreteSliderComponent>
                        </div>
                        <div>
                        <Grid container spacing={2} direction="row" justify="center" alignItems="center">
                            <Grid item xs={4}>
                                <Button variant="contained" onClick={AddReviewsMakeLoadTest}color="primary">{'Make '+numberRequest+' Request'}</Button>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-outlined-label">Brand</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={brand}
                                        onChange={handleChangeSelect}
                                        >
                                    <MenuItem value={brands[0]}>{brands[0]}</MenuItem>
                                    <MenuItem value={brands[1]}>{brands[1]}</MenuItem>
                                    <MenuItem value={brands[2]}>{brands[2]}</MenuItem>
                                    <MenuItem value={brands[3]}>{brands[3]}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-outlined-label">Product</InputLabel>
                                    <Select
                                    
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={product}
                                        onChange={handleChangeSelect1}
                                        >
                                    <MenuItem value={products[0]}>{products[0]}</MenuItem>
                                    <MenuItem value={products[1]}>{products[1]}</MenuItem>
                                    <MenuItem value={products[2]}>{products[2]}</MenuItem>
                                    <MenuItem value={products[3]}>{products[3]}</MenuItem>
                                    <MenuItem value={products[4]}>{products[4]}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        </div>
                        <div style={{marginBottom:'5px',marginTop:'30px'}}>
                            <Grid container spacing={2} direction="row" justify="center" alignItems="center">
                                <Grid item xs={6}>
                                    <SuccessfulTypography variant="h5">
                                        {'Sucessful: '+ goodResponses.length}
                                    </SuccessfulTypography>
                                </Grid>
                                <Grid item xs={6}>
                                    <ErrorTypography variant="h5">
                                        {'Error: '+ badResponses.length}
                                    </ErrorTypography>
                                </Grid>
                            </Grid>
                        </div>
                        <div style={{marginBottom:'5px',marginTop:'30px'}}>
                            <Typography style={{textAlign:'left'}}>
                                {'STATS: ExecutionTime: '+executionTime+'ms.'}
                            </Typography>
                        </div>
                    </Paper>
                </Grid>
                <Grid item item lg={12} sm={12} xl={12} xs={12} >
                    <Paper className={classes.paper}>
                    <div style={{margin:5}}>
                            <Typography variant='h4'>
                                GetReviewsPaginated
                            </Typography>
                        </div>
                        <div>
                        <Grid container spacing={2} direction="row" justify="center" alignItems="center">
                            <Grid item xs={3}>
                                <Button variant="contained" onClick={GetReviewsMakeLoadTest}color="primary">{'Make Request'}</Button>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-outlined-label">Brand</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={brand1}
                                        onChange={handleChangeSelect2}
                                        >
                                    <MenuItem value={localBrands[0]}>{localBrands[0]}</MenuItem>
                                    <MenuItem value={localBrands[1]}>{localBrands[1]}</MenuItem>
                                    <MenuItem value={localBrands[2]}>{localBrands[2]}</MenuItem>
                                    <MenuItem value={localBrands[3]}>{localBrands[3]}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-outlined-label">Product</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={product1}
                                        onChange={handleChangeSelect3}
                                        >
                                    <MenuItem value={'none'}>none</MenuItem>
                                    <MenuItem value={products[0]}>{products[0]}</MenuItem>
                                    <MenuItem value={products[1]}>{products[1]}</MenuItem>
                                    <MenuItem value={products[2]}>{products[2]}</MenuItem>
                                    <MenuItem value={products[3]}>{products[3]}</MenuItem>
                                    <MenuItem value={products[4]}>{products[4]}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField 
                                    fullWidth
                                    type="number"
                                    InputProps={{
                                        inputProps: { 
                                            max: 10, min: 2 
                                        }
                                    }}
                                    label="Limit"
                                    onChange={handleChangeSelectLimit1}
                                />
                            </Grid>
                        </Grid>
                        </div>
                        <div style={{marginBottom:'5px',marginTop:'30px'}}>
                            <Grid container spacing={2} direction="row" justify="center" alignItems="center">
                                <Grid item xs={6}>
                                    <SuccessfulTypography variant="h5">
                                        {'Sucessful: '+ goodResponses1.length}
                                    </SuccessfulTypography>
                                </Grid>
                                <Grid item xs={6}>
                                    <ErrorTypography variant="h5">
                                        {'Error: '+ badResponses1.length}
                                    </ErrorTypography>
                                </Grid>
                            </Grid>
                        </div>
                        <div style={{marginBottom:'5px',marginTop:'30px'}}>
                            <Typography style={{textAlign:'left'}}>
                                {'STATS: ExecutionTime: '+executionTime1+'ms.'}
                            </Typography>
                        </div>
                        <main className={clsx(classes.content)}>
                            <ReactJson
                                    src={total1}
                                    collapsed={true}
                                    name={"data"}
                                    displayDataTypes={true}
                                    indentWidth={2}
                                />
                        </main>
                    </Paper>
                </Grid>
                <Grid item item lg={12} sm={12} xl={12} xs={12} >
                    <Paper className={classes.paper}>
                    <div style={{margin:5}}>
                            <Typography variant='h4'>
                                GetNotApprovedReviewsPaginated
                            </Typography>
                        </div>
                        <div>
                        <Grid container spacing={2} direction="row" justify="center" alignItems="center">
                            <Grid item xs={3}>
                                <Button variant="contained" onClick={GetNotApprovedReviewsMakeLoadTest}color="primary">{'Make Request'}</Button>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-outlined-label">Brand</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={brand2}
                                        onChange={handleChangeSelect4}
                                        >
                                    <MenuItem value={localBrands[0]}>{localBrands[0]}</MenuItem>
                                    <MenuItem value={localBrands[1]}>{localBrands[1]}</MenuItem>
                                    <MenuItem value={localBrands[2]}>{localBrands[2]}</MenuItem>
                                    <MenuItem value={localBrands[3]}>{localBrands[3]}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-outlined-label">Product</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={product2}
                                        onChange={handleChangeSelect5}
                                        >
                                    <MenuItem value={'none'}>none</MenuItem>
                                    <MenuItem value={products[0]}>{products[0]}</MenuItem>
                                    <MenuItem value={products[1]}>{products[1]}</MenuItem>
                                    <MenuItem value={products[2]}>{products[2]}</MenuItem>
                                    <MenuItem value={products[3]}>{products[3]}</MenuItem>
                                    <MenuItem value={products[4]}>{products[4]}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField 
                                    fullWidth
                                    type="number"
                                    InputProps={{
                                        inputProps: { 
                                            max: 10, min: 2 
                                        }
                                    }}
                                    label="Limit"
                                    onChange={handleChangeSelectLimit2}
                                />
                            </Grid>
                        </Grid>
                        </div>
                        <div style={{marginBottom:'5px',marginTop:'30px'}}>
                            <Grid container spacing={2} direction="row" justify="center" alignItems="center">
                                <Grid item xs={6}>
                                    <SuccessfulTypography variant="h5">
                                        {'Sucessful: '+ goodResponses2.length}
                                    </SuccessfulTypography>
                                </Grid>
                                <Grid item xs={6}>
                                    <ErrorTypography variant="h5">
                                        {'Error: '+ badResponses2.length}
                                    </ErrorTypography>
                                </Grid>
                            </Grid>
                        </div>
                        <div style={{marginBottom:'5px',marginTop:'30px'}}>
                            <Typography style={{textAlign:'left'}}>
                                {'STATS: ExecutionTime: '+executionTime2+'ms.'}
                            </Typography>
                        </div>
                        <main className={clsx(classes.content)}>
                        <ReactJson
                                src={total2}
                                collapsed={true}
                                name={"data"}
                                displayDataTypes={true}
                                indentWidth={2}
                            />
                        </main>
                    </Paper>
                </Grid>
                <Grid item item lg={12} sm={12} xl={12} xs={12} >
                    <Paper className={classes.paper}>
                    <div style={{margin:5}}>
                            <Typography variant='h4'>
                                GetApprovedReviewsPaginated
                            </Typography>
                        </div>
                        <div>
                        <Grid container spacing={2} direction="row" justify="center" alignItems="center">
                            <Grid item xs={3}>
                                <Button variant="contained" onClick={GetApprovedReviewsMakeLoadTest}color="primary">{'Make Request'}</Button>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-outlined-label">Brand</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={brand3}
                                        onChange={handleChangeSelect6}
                                        >
                                    <MenuItem value={localBrands[0]}>{localBrands[0]}</MenuItem>
                                    <MenuItem value={localBrands[1]}>{localBrands[1]}</MenuItem>
                                    <MenuItem value={localBrands[2]}>{localBrands[2]}</MenuItem>
                                    <MenuItem value={localBrands[3]}>{localBrands[3]}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-outlined-label">Product</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={product3}
                                        onChange={handleChangeSelect7}
                                        >
                                    <MenuItem value={'none'}>none</MenuItem>
                                    <MenuItem value={products[0]}>{products[0]}</MenuItem>
                                    <MenuItem value={products[1]}>{products[1]}</MenuItem>
                                    <MenuItem value={products[2]}>{products[2]}</MenuItem>
                                    <MenuItem value={products[3]}>{products[3]}</MenuItem>
                                    <MenuItem value={products[4]}>{products[4]}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField 
                                    fullWidth
                                    type="number"
                                    InputProps={{
                                        inputProps: { 
                                            max: 10, min: 2 
                                        }
                                    }}
                                    label="Limit"
                                    onChange={handleChangeSelectLimit3}
                                />
                            </Grid>
                        </Grid>
                        </div>
                        <div style={{marginBottom:'5px',marginTop:'30px'}}>
                            <Grid container spacing={2} direction="row" justify="center" alignItems="center">
                                <Grid item xs={6}>
                                    <SuccessfulTypography variant="h5">
                                        {'Sucessful: '+ goodResponses3.length}
                                    </SuccessfulTypography>
                                </Grid>
                                <Grid item xs={6}>
                                    <ErrorTypography variant="h5">
                                        {'Error: '+ badResponses3.length}
                                    </ErrorTypography>
                                </Grid>
                            </Grid>
                        </div>
                        <div style={{marginBottom:'5px',marginTop:'30px'}}>
                            <Typography style={{textAlign:'left'}}>
                                {'STATS: ExecutionTime: '+executionTime3+'ms.'}
                            </Typography>
                        </div>
                        <main className={clsx(classes.content)}>
                        <ReactJson
                                src={total3}
                                collapsed={true}
                                name={"data"}
                                displayDataTypes={true}
                                indentWidth={2}
                            />
                        </main>
                    </Paper>
                </Grid>
                <Grid item item lg={12} sm={12} xl={12} xs={12} >
                    <Paper className={classes.paper}>
                    <div style={{margin:5}}>
                            <Typography variant='h4'>
                                GetPendingReviewsPaginated
                            </Typography>
                        </div>
                        <div>
                        <Grid container spacing={2} direction="row" justify="center" alignItems="center">
                            <Grid item xs={3}>
                                <Button variant="contained" onClick={GetPendingReviewsMakeLoadTest}color="primary">{'Make Request'}</Button>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-outlined-label">Brand</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={brand4}
                                        onChange={handleChangeSelect8}
                                        >
                                    <MenuItem value={localBrands[0]}>{localBrands[0]}</MenuItem>
                                    <MenuItem value={localBrands[1]}>{localBrands[1]}</MenuItem>
                                    <MenuItem value={localBrands[2]}>{localBrands[2]}</MenuItem>
                                    <MenuItem value={localBrands[3]}>{localBrands[3]}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-outlined-label">Product</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={product4}
                                        onChange={handleChangeSelect9}
                                        >
                                    <MenuItem value={'none'}>none</MenuItem>
                                    <MenuItem value={products[0]}>{products[0]}</MenuItem>
                                    <MenuItem value={products[1]}>{products[1]}</MenuItem>
                                    <MenuItem value={products[2]}>{products[2]}</MenuItem>
                                    <MenuItem value={products[3]}>{products[3]}</MenuItem>
                                    <MenuItem value={products[4]}>{products[4]}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField 
                                    fullWidth
                                    type="number"
                                    InputProps={{
                                        inputProps: { 
                                            max: 10, min: 2 
                                        }
                                    }}
                                    label="Limit"
                                    onChange={handleChangeSelectLimit4}
                                />
                            </Grid>
                        </Grid>
                        </div>
                        <div style={{marginBottom:'5px',marginTop:'30px'}}>
                            <Grid container spacing={2} direction="row" justify="center" alignItems="center">
                                <Grid item xs={6}>
                                    <SuccessfulTypography variant="h5">
                                        {'Sucessful: '+ goodResponses4.length}
                                    </SuccessfulTypography>
                                </Grid>
                                <Grid item xs={6}>
                                    <ErrorTypography variant="h5">
                                        {'Error: '+ badResponses4.length}
                                    </ErrorTypography>
                                </Grid>
                            </Grid>
                        </div>
                        <div style={{marginBottom:'5px',marginTop:'30px'}}>
                            <Typography style={{textAlign:'left'}}>
                                {'STATS: ExecutionTime: '+executionTime4+'ms.'}
                            </Typography>
                        </div>
                        <main className={clsx(classes.content)}>
                            <ReactJson
                                src={total4}
                                collapsed={true}
                                name={"data"}
                                displayDataTypes={true}
                                indentWidth={2}
                            />
                        </main>
                    </Paper>  
                </Grid>
            </Grid>   
        </div>
    );
}