import React, { useState,useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Rating from '@material-ui/lab/Rating';
import firebaseConfig from '../../FirebaseConfig';
import Typography from '@material-ui/core/Typography';
import {makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  paper: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#F5F5F5',
    padding:'10px'
  },
  typography:{
    fontSize:'bolder',
    marginTop:'10px',
    marginBottom:'10px',
    color:'gray',
    fontWeight:'bolder'
  },
}));

export default function AverageViewComponent(props) {
  const classes = useStyles();
  const [average, setAverage] = useState(0)
  const [reviews, setReviews] = useState(0)

  const {
    idBrand,
    idProduct
  } = props;

  function getAverageByProductID(){
    /*let caculateAverageByProduct = firebaseConfig.functions().httpsCallable('caculateAverageByProduct');
    caculateAverageByProduct({
      idBrand: idBrand,
      idProduct: idProduct
    }).then((result)=> {

        setAverage(result.data.average)
        setReviews(result.data.reviews)
    })*/

    fetch("https://reviews-axo-dev.wallia.io/calculateAverageByProduct",{
      mode: 'cors', // no-cors, *cors, same-origin
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjhiMjFkNWE1Y2U2OGM1MjNlZTc0MzI5YjQ3ZDg0NGE3YmZjODRjZmYiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiQ3Jpc3RpYW4gU2VycmFubyIsImRldmVsb3BlciI6dHJ1ZSwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL2dydXBvLWF4by1kZXYiLCJhdWQiOiJncnVwby1heG8tZGV2IiwiYXV0aF90aW1lIjoxNjI0OTMxMDk4LCJ1c2VyX2lkIjoiSEdFZEVnZk9vN1Y1SDRjOXF5cjVDMmI3QkJ1MSIsInN1YiI6IkhHRWRFZ2ZPbzdWNUg0YzlxeXI1QzJiN0JCdTEiLCJpYXQiOjE2MjQ5MzEwOTgsImV4cCI6MTYyNDkzNDY5OCwiZW1haWwiOiJjcmlzdGlhbi5zZXJyYW5vQHdhbGxpYS5pbyIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJlbWFpbCI6WyJjcmlzdGlhbi5zZXJyYW5vQHdhbGxpYS5pbyJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.VlDqZGYY9h7ZVvhfFohe_xPDYp01YfKtOPd_6EMoRXzSmjVhMDxjrE1aPr0Ho4j3DMLg538yH6PO-Iy0RO1qrXR0zsBJZ5zflSHWCZPpw6kZr1RnoGMzLvE4q5z6cWG02fm-QHcG9f2zXrSCbSIyHJ_pf6roGZnx68v97sbrDpoQcZQ_C6I_LuiEjcg7XH36my6DUEzfb_AVVcZhcmJeWEsGHZBAzMWKQig9BT6Bhi6zmwnJlGzymPSAbrcq7X6l6K6ULwzV8pbZrU94BVThTiiSADkP9wFkO83MCysGqBkuRlIwys03rnVCS6rWsQgSKQilvpnct8KsK2ZKp8f_dw',
        },
      body: JSON.stringify({
        idBrand: idBrand,
        idProduct: idProduct
      })
    })
    .then(res => res.json())
    .then(
      (result) => {
        console.log(result)
        setAverage(result.average)
        setReviews(result.reviews)
      },(error) => {
        console.log(error)
    })
  }

  useEffect(()=>{
    getAverageByProductID()
  },[])

  return (
    <div className={classes.paper}>     
      <Grid container spacing={1} direction="column" justify="center" alignItems="flex-start">
        <Grid item item lg={12} sm={12} xl={12} xs={12} >
          <Rating name="read-only" value={parseInt(average)} size="large" readOnly />
        </Grid>
        <Grid item item lg={12} sm={12} xl={12} xs={12} >
          <Typography component={'span'} style={{fontWeight:'bold',paddingLeft:10}}>
            {reviews+' reviews'}
          </Typography>
        </Grid>
      </Grid>   
    </div>
  );
}