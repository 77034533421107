import React,{useContext,useEffect} from 'react';
import {Route, Redirect} from 'react-router-dom';
import {AuthContext} from './AuthProvider';
import PropTypes from 'prop-types';

const PrivateRoute = ({ layout:Layout, component: RouteComponent, idBrand, idProduct, idUser,on,brands, ...rest}) => {
  const {currentUser}= useContext(AuthContext);

  useEffect(()=>{
    console.log(currentUser)
  },[])
  return(
      <Route
          {...rest}
          render={routeProps =>
              currentUser ? (
                <Layout on={on}>
                  <RouteComponent {...routeProps} idBrand={idBrand} idProduct={idProduct} idUser={idUser} brands={brands}/>
                </Layout>
              ):(
              <Redirect to={'/sign-in'}/>
          )
      }
      />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string
};


export default PrivateRoute;