import './App.css';
import React, { Component } from 'react';
import { AuthProvider } from './AuthProvider';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { green, purple } from '@material-ui/core/colors';
import { BrowserRouter } from 'react-router-dom';
import Routes from './Routes';
const baseTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#E74C3C'
    },
    secondary: {
      main: green[500]
    }
  }
});

export default class App extends Component {
  render() {
    return (
      <ThemeProvider theme={baseTheme}>
        <AuthProvider>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </AuthProvider>
      </ThemeProvider>
    );
  }
}
