import React, {useState,useEffect,useContext} from 'react';
import { Redirect } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import firebaseConfig from '../../FirebaseConfig';
import validate from 'validate.js';
import { AuthContext } from '../../AuthProvider';
const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  }
};

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://wallia.io/">
        Wallia.io
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(https://wallia.io/wp-content/uploads/2019/08/1.png)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  
}));

const ColorButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText('#E74C3C'),
      backgroundColor: '#E74C3C',
      '&:hover': {
        backgroundColor: '#E74C3C',
      },
    },
  }))(Button);

  const ErrorTheme = createMuiTheme({
    overrides: {
      MuiSnackbarContent: {
        root: {
          backgroundColor: '#CB4335'
        }
      }
    }
  });
  const DoneTheme = createMuiTheme({
    overrides: {
      MuiSnackbarContent: {
        root: {
          backgroundColor: '#28B463'
        }
      }
    }
  });

export default function SignInViewComponent() {
  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  const [openSnackBarError, setOpenSnackBarError] = useState(false);
  const [openSnackBarDone, setOpenSnackBarDone] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');

  const handleSignIn = event => {
    event.preventDefault();
    const { email, password } = event.target.elements;
    firebaseConfig
      .auth()
      .signInWithEmailAndPassword(email.value, password.value)
      .then(() => {
        firebaseConfig
          .auth()
          .currentUser.getIdTokenResult()
          .then(idTokenResult => {
            if (
              !idTokenResult.claims.developer
            ) {
              firebaseConfig
                .auth()
                .signOut()
                .then(() => {
                  setSnackBarMessage('Permission denied');
                  setOpenSnackBarError(true);
                });
            } else {
              setSnackBarMessage('Welcome');
              setOpenSnackBarDone(true);
            }
          })
          .catch(error => {
            firebaseConfig
              .auth()
              .signOut()
              .then(() => {
                setSnackBarMessage(error.message);
                setOpenSnackBarError(true);
              });
          });
      })
      .catch(error => {
        setSnackBarMessage(error.message);
        setOpenSnackBarError(true);
      });
  };

  const handleChange = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleCloseSnackBar = () => {
    setOpenSnackBarDone(false);
    setOpenSnackBarError(false);
  };

  useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;


  const { currentUser } = useContext(AuthContext);

  if (currentUser) {
    return <Redirect to="/functions-docs" />;
  }
  

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} onSubmit={handleSignIn} noValidate>
            <TextField
              className={classes.textField}
              error={hasError('email')}
              fullWidth
              helperText={
                hasError('email') ? formState.errors.email[0] : null
              }
              label="Email address"
              name="email"
              onChange={handleChange}
              type="text"
              value={formState.values.email || ''}
              variant="outlined"
              margin="normal"
              required
              id="email"
              autoComplete="current-email"
            />
            <TextField
              className={classes.textField}
              error={hasError('password')}
              fullWidth
              helperText={
                hasError('password') ? formState.errors.password[0] : null
              }
              label="Password"
              name="password"
              onChange={handleChange}
              type="password"
              value={formState.values.password || ''}
              variant="outlined"
              margin="normal"
              required
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <ColorButton    
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={!formState.isValid}
              >
                Sign In
              </ColorButton>

            {/*<Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>*/}
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
        <ThemeProvider theme={ErrorTheme}>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          open={openSnackBarError}
          autoHideDuration={6000}
          message={snackBarMessage}
          onClose={handleCloseSnackBar}
        />
        </ThemeProvider>
        <ThemeProvider theme={DoneTheme}>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            open={openSnackBarDone}
            autoHideDuration={6000}
            message={snackBarMessage}
            onClose={handleCloseSnackBar}
          />
        </ThemeProvider>
      </Grid>
    </Grid>
  );
}