import React, { useEffect } from 'react';
import { makeStyles,withStyles,fade } from '@material-ui/core/styles';
import clsx from 'clsx';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import InputBase from '@material-ui/core/InputBase';
import ReactJson from 'react-json-view'
import LinearProgress from '@material-ui/core/LinearProgress';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1a90ff',
  },
}))(LinearProgress);

const BootstrapInput = withStyles((theme) => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.common.white,
      border: '1px solid #ced4da',
      fontSize: 16,
      width: 'auto',
      padding: '10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  }))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
  },
  column: {
    flexBasis: '40%',
  },
  helper: {
    
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor:'#252525',
    maxHeight:'200px',
    overflow: 'auto',
    color:'#00FF6B',
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
  },
}));

export default function AccordionComponent(props) {
  const classes = useStyles();

  const {
    onClickAction,
    data,doc,
  } = props;

  return (
    <div className={classes.root}>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header">
          <Typography className={classes.heading}>Documentación</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <Grid container spacing={0} direction="column" justify="flex-start">
            <Grid item xs={12}>
              <Typography variant="caption" paragraph align="left">
                {doc.description}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption" paragraph align="left">
                {doc.paramsRequired}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption" paragraph align="left">
                {doc.paramsOpcional}
              </Typography>
            </Grid>
            {/*<Grid item xs={12}>
              <Typography variant="caption" paragraph  component={'span'} align="left">
                Ejemplo de implementacion con SDK de firebase:
                <pre style={{ fontFamily: 'inherit', backgroundColor:'#F4F4F4'}}>
                {doc.implement1}
                </pre>
              </Typography>
            </Grid>*/}
            <Grid item xs={12}>
              <Typography variant="caption" paragraph  align="left" component={'span'}>
                Simple request
                <pre style={{ fontFamily: 'inherit', backgroundColor:'#F4F4F4'}}>
                {doc.implement2}
                </pre>
              </Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
        <AccordionActions>        
          <main className={clsx(classes.content)}>
            <Typography variant="caption" paragraph align="left" component={'span'}>
              response:
              
              {JSON.stringify(data)}
              
            </Typography>
          </main> 
          <Button onClick={onClickAction} variant="contained" size="small"color="primary">Request</Button>
        </AccordionActions>
      </Accordion>
      {/*<BorderLinearProgress variant="determinate" value={50} />*/}
    </div>
  );
}