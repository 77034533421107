import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  margin: {
    height: theme.spacing(3),
  },
}));

const marks = [
  {
    value: 32,
    label: '32 request',
  },
  {
    value: 64,
    label: '64 request',
  },
  {
    value: 128,
    label: '128 request',
  },
  {
    value: 256,
    label: '256 request',
  },
];

function valuetext(value) {
  return `${value}R`;
}

export default function DiscreteSliderComponent(props) {
  const classes = useStyles();
  const {onChange,max, ...other } = props;
  return (
    <div className={classes.root}>
      <Typography id="discrete-slider-custom" gutterBottom>
        Number of requets
      </Typography>
      <Slider
        defaultValue={0}
        onChange={onChange}
        getAriaValueText={valuetext}
        aria-labelledby="discrete-slider-custom"
        step={1}
        valueLabelDisplay="auto"
        max={256}
        min={0}
        marks={marks}
      />
    </div>
  );
}